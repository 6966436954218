<template>
    <div>
        <div>
            <login/>
        </div>
    </div>
</template>

<script>

import login from '@/components/login/login.vue'
export default {
    components:{ login },
}

</script>
import HTTPService from "@/services/http_service.js";

export default class AgendaEspecialidadeService extends HTTPService {
  resource = "agenda/unidade/especialidades";
  resource2 = "agenda/especialidade/unidade";
  resource3 = "agendamento/recepcao/especialidade";
  resource4 = "especialidade-procedimento/unidade/especialidade";

  static build() {
    return new AgendaEspecialidadeService();
  }

  getAgendaEspecialidadeByUnidadeId(unidadeId) {
    return this.get(`${this.resource}/${unidadeId}`);
  }
  getAgendaEspecialidadebyRecepcao(unidadeId, especialidadeId, dataPesquisa) {
    dataPesquisa = dataPesquisa.replaceAll("-", "");
    return this.get(
      `${this.resource2}/${especialidadeId}/${unidadeId}/${dataPesquisa}`
    );
  }
  getProcesamentobyUnidadeEspecialidade(unidadeId, especialidadeId) {
    return this.get(`${this.resource4}/${unidadeId}/${especialidadeId}`);
  }
  postAgendamentoRecepcao(model) {
    return this.post(this.resource3, { ...model });
  }
  postAgendamentoRecepcaoUpdate(model) {
    return this.post(`${this.resource3}/alterar`, { ...model });
  }
}

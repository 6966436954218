import HTTPService from "@/services/http_service.js";

export default class UnidadeService extends HTTPService {
  static build() {
    return new UnidadeService();
  }
  /**
        @returns {Promise<Response>}
     */
  getUnidadePaginatedList({ page, per_page, search, order, desc }) {
    return this.get("unidade", { page, per_page, search, order, desc });
  }

  getUnidadeById(unidade_id) {
    return this.get(`unidade/${unidade_id}`).then((resp) => resp.json());
  }
  // /**
  //  @returns {Promise<Response>}
  //  */
  // getUnidadeList () {
  //     return this.get('unidade')
  // }

  /**
     @returns {Promise<{observacao: String, estado: String, cidade: String, telefone1: String, numero: String, bairro: String, referencias: String, cnpj: string|String, cepId: String, telefone2: String, cep: String, nomeFantasia: string|String, complemento: String, enderecoId: String, logradouro: String, pessoaJuridicaId: String, pessoaId: String, razaoSocial: string}[]>}
     */
  async getUnidadeList() {
    try {
      const response = await this.get("unidade");
      if (response.status !== 200) return [];
      const { data } = await response.json();
      return data.unidades.map((unidade) => ({
        pessoaJuridicaId: ~~unidade.pessoa_juridica_id,
        nomeFantasia: unidade.nome_fantasia || "",
        razaoSocial: unidade.razao_social || "",
        cnpj: unidade.cnpj || "",
        pessoaId: ~~unidade.pessoa_id,
        telefone1: unidade.telefone_1 || "",
        telefone2: unidade.telefone_2 || "",
        observacao: unidade.observacao || "",
        enderecoId: unidade.endereco_id,
        cep: unidade.cep || "",
        numero: unidade.numero || "",
        complemento: unidade.complemento || "",
        referencias: unidade.referencias || "",
        cepId: ~~unidade.cep_id,
        estado: unidade.estado || "",
        cidade: unidade.cidade || "",
        bairro: unidade.bairro || "",
        logradouro: unidade.logradouro || "",
        unidadeId: unidade.unidade_id || "",
        unidadeDescricao: unidade.descricao || "",
      }));
    } catch (e) {
      return [];
    }
  }

  /**
     @returns {Promise<Response>}
     */
  cadastrarUnidade({
    nome_fantasia,
    razao_social,
    cnpj,
    telefone_1,
    telefone_2,
    cep,
    estado,
    cidade,
    bairro,
    logradouro,
    numero,
    complemento,
    referencias,
  }) {
    const unidade = {
      nome_fantasia,
      razao_social,
      cnpj,
      telefone_1,
      telefone_2,
      cep,
      estado,
      cidade,
      bairro,
      logradouro,
      numero,
      complemento,
      referencias,
    };
    return this.post("unidade", unidade);
  }

  /**
     @returns {Promise<Response>}
     */
  atualizarUnidade(
    unidadeId,
    pessoaJuridicaId,
    {
      nome_fantasia,
      descricao,
      razao_social,
      cnpj,
      telefone_1,
      telefone_2,
      cep,
      estado,
      cidade,
      bairro,
      logradouro,
      numero,
      complemento,
      referencias,
    }
  ) {
    if (~~pessoaJuridicaId <= 0) {
      throw new Error(
        "Invalid parameter pessoaJuridicaId: " + pessoaJuridicaId
      );
    }
    const unidade = {
      nome_fantasia,
      descricao,
      razao_social,
      cnpj,
      telefone_1,
      telefone_2,
      cep,
      estado,
      cidade,
      bairro,
      logradouro,
      numero,
      complemento,
      referencias,
    };
    return this.post(`unidade/${unidadeId}/${pessoaJuridicaId}`, unidade);
  }
}

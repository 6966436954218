<template>
  <div>
    <v-card min-height="100vh">
      <v-toolbar style="background-color: #1daf80; color: #fff">
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span class="headline">Prontuários</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <div class="row">
            <div class="col-12 text-end mt-3 mb-5">
              <v-dialog fullscreen v-model="dialogAddAnaminese">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="pacienteInfo && unidadeId != 24"
                    class="btn-style-green"
                    color="#3E682A"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span>Anamnese Adicional</span>
                  </v-btn>
                </template>
                <v-card>
                  <v-toolbar style="background-color: #1daf80; color: #fff">
                    <v-btn icon dark @click="dialogAddAnaminese = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>
                      <span class="headline">Anamnese Adicional</span>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items> </v-toolbar-items>
                  </v-toolbar>
                  <modal-nova-anaminese
                    v-if="dialogAddAnaminese"
                    :paciente="paciente"
                    @close="(dialogAddAnaminese = false), listProntuarios()"
                  />
                </v-card>
              </v-dialog>
            </div>
          </div>
          <v-expansion-panels v-model="panel">
            <v-expansion-panel
              @click="initConsulta(item, i)"
              class="m-2"
              v-for="(item, i) in consultasWithEdited"
              :key="i"
            >
              <span class="border d-flex justify-content-between p-2 px-5">
                <b> Executor: {{ item.nome_assistente || item.nome_medico }}</b>
                <b class="text-white orange px-3" v-if="item.req_st == 5">
                  Anamnese Adicional
                </b>
              </span>
              <v-expansion-panel-header v-slot="{ open }">
                <v-row no-gutters>
                  <v-col cols="4" class="align-self-center">
                    Data: {{ item.data | data }} <br />
                  </v-col>
                  <v-col cols="8" class="text--secondary">
                    <v-fade-transition leave-absolute>
                      <span v-if="open" key="0" class="d-flex">
                        <v-col cols="10" class="align-self-center">
                          Procedimento:{{ item.procedimento }}
                        </v-col>
                      </span>
                      <span v-else key="1" class="d-flex">
                        <v-col cols="7" class="align-self-center">
                          {{ item.procedimento }}
                        </v-col>
                        <v-col cols="5"> Há {{ calcular_tempo(item) }} </v-col>
                      </span>
                    </v-fade-transition>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <template v-if="consulta.auditoria">
                  <h4>Processo realizado pela auditoria</h4>
                </template>
                <template v-else-if="consulta.formulario_id">
                  <v-card elevation="0">
                    <v-tabs color="green darken-4">
                      <v-tab>
                        <h5>Resumo</h5>
                      </v-tab>
                      <!-- <v-tab v-if="classificarFotos3 > 0">
                        <h5>Classificar fotos</h5>
                      </v-tab>
                      <v-tab v-if="Fotosclassificadas1 > 0">
                        <h5>Antes e depois</h5>
                      </v-tab> -->

                      <!-- RESUMO -->
                      <v-tab-item>
                        <v-card flat :loading="loading">
                          <v-card-text>
                            <v-row
                              no-gutters
                              class="text-left flex-column"
                              v-if="!loading"
                            >
                              <v-row class="justify-end">
                                <v-btn
                                  color="green darken-3"
                                  class="mx-2"
                                  small
                                  @click="imprimirAnamnese(item)"
                                >
                                  <v-icon color="#ffffff"> mdi mdi-eye </v-icon>
                                </v-btn>
                                <v-btn
                                  color="green darken-3"
                                  small
                                  v-if="checkEdited(item)"
                                  @click="item.editedItem = !item.editedItem"
                                >
                                  <v-icon
                                    v-if="item.editedItem"
                                    color="#ffffff"
                                  >
                                    mdi-pencil
                                  </v-icon>
                                  <v-icon v-else color="#ffffff">
                                    mdi-close
                                  </v-icon>
                                </v-btn>
                              </v-row>
                              <v-row class="flex-column">
                                <label for="resumo"
                                  ><strong>Resumo: </strong>
                                </label>
                                <v-text-field
                                  :disabled="item.editedItem"
                                  id="resumo"
                                  v-model="consulta.consulta_descricao"
                                ></v-text-field>
                              </v-row>
                              <v-row>
                                <div
                                  v-for="(pergunta,
                                  index) in consulta.perguntas"
                                  :key="index"
                                  :class="
                                    pergunta.id_tipo_pergunta === 2
                                      ? 'paragrafo'
                                      : ''
                                  "
                                >
                                  <consulta-fill
                                    @rchange="atributeModel"
                                    :pergunta="pergunta"
                                    :disabled="!item.editedItem"
                                  />
                                </div>
                              </v-row>
                              <v-row class="flex-column">
                                <v-col>
                                  <v-btn
                                    color="green darken-3"
                                    @click="atualizarProntuario"
                                    v-if="!item.editedItem"
                                  >
                                    <v-icon color="#ffffff">
                                      mdi-pencil
                                    </v-icon>
                                    <span style="color: #ffffff"
                                      >Salvar alterações</span
                                    >
                                  </v-btn>
                                </v-col>
                              </v-row>

                              <v-row class="flex-column">
                                <h4 class="pl-3">Adendos</h4>
                              </v-row>

                              <v-row class="flex-column">
                                <p
                                  class="adendo"
                                  :key="adendo.id"
                                  v-for="adendo in adendos"
                                >
                                  (<b v-if="adendo.executor"
                                    >{{ adendo.executor }} -
                                  </b>
                                  <span
                                    >{{
                                      adendo.data
                                        .split("-")
                                        .reverse()
                                        .join("/")
                                    }}
                                    - {{ adendo.hora }}</span
                                  >) -
                                  {{ adendo.descricao }}
                                </p>
                              </v-row>

                              <v-row class="flex-column">
                                <v-col>
                                  <v-text-field
                                    v-model="adendo[i]"
                                  ></v-text-field>
                                </v-col>

                                <v-col>
                                  <v-btn
                                    @click="addAdendo(i)"
                                    class="green darken-1 white--text"
                                    >Adicionar</v-btn
                                  >
                                </v-col>
                              </v-row>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                    </v-tabs>
                  </v-card>
                </template>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-container>
      </v-card-text>
      <v-dialog v-model="dialogImprimir" fullscreen>
        <v-card>
          <v-toolbar style="background-color: #3e682a; color: #fff">
            <v-btn icon dark @click="dialogImprimir = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>
              <span class="headline"
                >Anamnese - {{ consultaImprimir.nome_pet }}</span
              >
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items> </v-toolbar-items>
          </v-toolbar>
          <v-card-text class="p-5">
            <v-row class="">
              <label for="resumo"><strong>Resumo: </strong> </label>
              <v-text-field
                id="resumo"
                readonly
                v-model="consultaImprimir.consulta_descricao"
              ></v-text-field>
            </v-row>
            <v-row class="">
              <div
                v-for="(pergunta, index) in consulta.perguntas"
                :key="index"
                :class="pergunta.id_tipo_pergunta === 2 ? 'paragrafo' : ''"
              >
                <consulta-fill @rchange="atributeModel" :pergunta="pergunta" />
              </div>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
// import FotosClassificadas from "@/components/prontuarios/fotos_classificadas.vue";
import ConsultaFill from "@/components/prontuarios/consulta_campo_fill.vue";
import ConsultaService from "@/services/consulta_service.js";
import AdendoService from "@/services/adendo_service.js";
import Mixin from "@/mixins/vuex_snackbar_mixin.js";
import VuexUsuarioMixin from "@/mixins/vuex_usuario_mixin.js";
import DadosCadastraisMixin from "@/mixins/dados_cadastrais_mixin.js";
import ModalNovaAnaminese from "@/components/recepcao_medico/infoChamada/nova_anaminese";
import PetsService from "@/services/pets_service.js";

export default {
  mixins: [Mixin, VuexUsuarioMixin, DadosCadastraisMixin],
  components: {
    ConsultaFill,
    ModalNovaAnaminese,
    // FotosClassificadas,
  },

  props: {
    consultas: Array,
    pacienteInfo: Object,
  },
  async mounted() {
    this.adendo = this.consultas.map(() => "");
    this.consultasWithEdited = this.consultas.map((value) => {
      return {
        ...value,
        editedItem: true,
      };
    });
    this.usuarioId = await JSON.parse(sessionStorage.vuex).usuario.id;
    this.paciente = {
      tutor_nome: this.pacienteInfo.nome,
      pet_nome: this.pacienteInfo.nome_pet,
      PetCodigo: this.pacienteInfo.id_pet,
      TutorCodigo: this.pacienteInfo.id_cliente,
    };
  },
  data: () => ({
    adendo: [],
    consultaImprimir: {},
    dialogImprimir: false,
    adendos: [],
    consultasWithEdited: [],
    consulta: {},
    panel: [],
    // loadingClassificarFotos:true,
    // loadingFotos:true,
    loading: true,
    open: false,
    date: null,
    editar: true,
    trip: {
      name: "",
      location: null,
      start: null,
      end: null,
    },
    formularioEditar: {},
    plc: {},
    usuarioId: "",
    dialogAddAnaminese: false,
    paciente: "",
    petService: PetsService.build(),
    unidadeId: JSON.parse(sessionStorage.vuex).unidade.id,
  }),
  watch: {
    panel(index) {
      if (index >= 0) {
        this.consulta = {};
        this.carregar_item(index);
      } else {
        this.consulta = {};
      }
    },
  },
  methods: {
    initConsulta(item, index) {
      this.formularioEditar = item;
      this.listAdendos(index);
    },

    imprimirAnamnese(consulta) {
      console.log(consulta);
      this.dialogImprimir = true;
      this.consultaImprimir = consulta;
    },

    checkEdited() {
      if (
        this.$_GETTER_perfil.descricao.toLowerCase() == "medico" &&
        this.consulta.usuario_medico_id == this.$_GETTERS_usuario.id
      ) {
        return true;
      }

      if (
        this.$_GETTER_perfil.descricao.toLowerCase() == "assistente" &&
        this.consulta.usuario_executor_exame == this.$_GETTERS_usuario.id
      ) {
        return true;
      }
      return false;
    },
    isBeforeOrToday(data) {
      const data_parts = data.split("-");
      const data_consulta = new Date(
        data_parts[0],
        data_parts[1],
        data_parts[2]
      );
      console.log({
        data_consulta,
        data_parts,
        a: data_consulta <= new Date(),
      });
      return data_consulta <= new Date();
    },

    atributeModel(change) {
      const newValue = change.alteracao;
      const perguntaId = change.pergunta.id;

      const pergunta = this.consulta.perguntas.find((e) => e.id === perguntaId);
      const respostaCampos = {
        1: "resposta_pergunta_textual",
        2: "resposta_pergunta_textual",
        3: "resposta_pergunta_opcao",
        4: "resposta_pergunta_opcao",
      };
      const respostaCampo = respostaCampos[pergunta.id_tipo_pergunta];
      pergunta[respostaCampo] = newValue;
    },
    atualizarProntuario() {
      let formulario = this.formularioEditar;

      formulario.id_consulta = Number.parseInt(
        this.formularioEditar.id_consulta
      );
      formulario.titulo = this.formularioEditar.consulta_titulo;
      formulario.descricao = this.consulta.consulta_descricao;
      formulario.id_formulario = this.consulta.formulario_id;

      formulario.respostas = this.consulta.perguntas.map((resposta) => {
        if (resposta.resposta_pergunta_textual) {
          resposta.resposta = resposta.resposta_pergunta_textual;
        }

        if (resposta.resposta_pergunta_decimal) {
          resposta.resposta = resposta.resposta_pergunta_decimal;
        }

        if (resposta.resposta_pergunta_inteira) {
          resposta.resposta = resposta.resposta_pergunta_inteira;
        }

        if (resposta.resposta_pergunta_opcao) {
          resposta.resposta = resposta.resposta_pergunta_opcao;
        }

        resposta.id_pergunta = resposta.id;

        return resposta;
      });

      this.plc = this.consulta;

      const consultaService = new ConsultaService();
      consultaService.update(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.form_resp = {};
              this.resp = [];
              this.$_ACTIONS_showSnackbarMessage({
                message: "Alterações salvas com sucesso",
                color: "sucess",
              });
              // this.$emit("finalizarConsulta", { msg:body.message, cor:"sucess", nome: this.paciente.nome} );
            } else {
              this.$_ACTIONS_showSnackbarMessage({
                message:
                  body.message || this.$global.messages.internalServerError,
                color: "error",
              });
            }
          },
          onError: (message) => {
            this.$_ACTIONS_showSnackbarMessage({
              message: message || this.$global.messages.internalServerError,
              color: "error",
            });
          },
          onEnd: () => {
            this.loadingNovoFormulario = false;
          },
        },
        formulario,
        formulario.id_consulta
      );
      this.formularioEditar.editedItem = true;
    },

    listAdendos(index) {
      const adendoService = new AdendoService();
      const consulta_id = this.consultas[index].id_consulta;

      adendoService.getList(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.adendos = body.data.adendos;
            }
          },
          onError: (error) => {
            console.log(error);
          },
          onEnd: () => {
            this.loading = false;
            this.adendo[index] = "";
          },
        },
        consulta_id
      );
    },

    addAdendo(index) {
      const adendoService = new AdendoService();

      const adendoVM = {
        consulta_id: this.consultas[index].id_consulta,
        descricao: this.adendo[index],
        usuario_id: this.usuarioId,
      };

      adendoService.criarAdendo(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.$_ACTIONS_showSnackbarMessage({
                message: body.message,
                color: "sucess",
              });

              this.listAdendos(index);
            }
          },
          onError: (error) => {
            this.$_ACTIONS_showSnackbarMessage({
              message: error.message,
              color: "error",
            });
          },
          onEnd: () => {
            this.adendo[index] = "";
          },
        },
        adendoVM
      );
    },

    calcular_tempo(i) {
      var data = new Date(i.data);
      var hj = new Date();
      var dif = hj.getTime() - data.getTime();
      var dias = dif / (1000 * 3600 * 24);
      dias = Math.floor(dias);
      var resp = "";
      if (dias > 8) {
        var semanas = Math.floor(dias / 7);
        dias -= semanas * 7;
        resp = `${semanas} sem. e ${dias} dia(s) `;
        if (semanas > 4) {
          var meses = Math.floor(semanas / 4);
          semanas -= meses * 4;
          resp = `${meses} m., ${semanas} sem. e ${dias} dia(s) `;
          if (meses > 12) {
            var anos = Math.floor(meses / 12);
            meses -= anos * 12;
            resp = `${meses} ano(s) , ${meses} m., ${semanas} sem. e ${dias} dia(s) `;
          }
        }
      } else {
        resp = dias + " dia(s)";
      }
      return resp;
    },
    carregar_item(index) {
      const consultaService = new ConsultaService();
      consultaService.get(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.consulta = body.data;
            } else if (status == 206) {
              this.consulta = { auditoria: true };
            }
          },
          onError: (error) => {
            console.log(error);
          },
          onEnd: () => {
            this.loading = false;
          },
        },
        this.consultas[index].id_consulta
      );

      //  setTimeout(() =>  this.loading = false, 1000);
      //     this.consulta = this.consultas[index]
    },
    sem_fotos_para_classificar() {
      this.loadingClassificarFotos = false;
      this.$_ACTIONS_showSnackbarMessage({
        message: "Não coseguimos encontrar as fotos",
        color: "error",
      });
    },
    sem_fotos() {
      this.loadingFotos = false;
      this.$_ACTIONS_showSnackbarMessage({
        message: "Não coseguimos encontrar as fotos",
        color: "error",
      });
    },
    async listProntuarios() {
      await this.petService
        .getConsultasbyUnidade(
          this.paciente.PetCodigo,
          JSON.parse(sessionStorage.vuex).unidade.id
        )
        // .getConsultas(this.paciente.PetCodigo)
        .then(async (response) => {
          const data = await response.json();
          if (response.status !== 200) {
            this.$_ACTIONS_showSnackbarMessage({
              message: data.message,
              color: response.status === 200 ? "sucess" : "error",
            });
          }
          console.log(data);
          this.consultas = data.data.consultas;
          this.consultasWithEdited = this.consultas.map((value) => {
            return {
              ...value,
              editedItem: true,
            };
          });
        })
        .finally(() => {});
    },
  },
};
</script>

<style scoped>
p {
  margin: 0px 10px !important;
  padding: 0px 10px !important;
  font-size: 16px;
}
.paragrafo {
  width: 770px !important;
}
label {
  font-weight: 900;
  font-size: 1.2rem;
}
.v-application {
  line-height: 0.01;
}
.btn-style-green {
  color: #fff;
  background-color: #1daf80 !important;
  height: 30px !important;
  font-size: 12px;
  padding: 10px !important;
  border-radius: 50px;
  margin: 20px 10px 20px 0px;
}
</style>

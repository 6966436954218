<template>
  <div>
    <v-card>
      <v-toolbar style="background-color: #1daf80; color: #fff">
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span class="headline" v-if="visualizar">Visualizar Tutor</span>
          <span class="headline" v-else>Editar paciente</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items> </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-form class="p-lg-3 row" ref="form">
            <!-- Editar foto -->
            <div class="col-lg-12">
              <div class="mx-auto my-4">
                <div v-if="!visualizar">
                  <h3>Alterar foto de perfil</h3>
                  <div>
                    <v-btn
                      class="mx-2"
                      fab
                      dark
                      color="#3e682a"
                      v-show="addFoto"
                      @click="addFoto = false"
                    >
                      <v-icon dark> mdi-plus </v-icon>
                    </v-btn>
                    <div class="mb-2" v-show="!addFoto">
                      <v-img
                        lazy-src="https://picsum.photos/id/11/10/6"
                        max-height="150"
                        max-width="250"
                        class="m-auto"
                        :src="preview"
                      ></v-img>
                      <v-file-input
                        v-model="foto"
                        accept="image/png, image/jpeg, image/bmp"
                        placeholder="Adicione uma foto"
                        prepend-icon="mdi-camera"
                        @click:clear="clean"
                        @change="onChange($event)"
                      ></v-file-input>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <v-img
                    v-if="image_perfil"
                    :src="image_perfil"
                    class="m-auto"
                    width="150"
                    height="150"
                  ></v-img>
                </div>
              </div>
            </div>

            <div class="col-lg-6 form-group clearfix mb-3">
              <label for="nome">Nome Completo</label>
              <v-text-field
                :error-messages="error.nome"
                v-model="paciente.nome"
                :disabled="visualizar"
                :rules="[
                  validationService.required('Campo Nome é obrigatório.'),
                  validationService.isNotEmpty(),
                ]"
                :type="'text'"
                placeholder="Seu nome completo"
                style="padding-top: 0 !important"
              >
              </v-text-field>
            </div>

            <div class="col-lg-6 form-group clearfix mb-3">
              <label for="emailCadastroUsuario">Email</label>
              <v-text-field
                :error-messages="error.email"
                v-model="paciente.email"
                :disabled="visualizar"
                :rules="[validationService.isEmail('Email inválido.')]"
                :type="'email'"
                placeholder="Escreva seu email"
                style="padding-top: 0 !important"
              ></v-text-field>
            </div>

            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="Telefone01">Telefone Celular:</label>
              <v-text-field
                v-mask="'(##)#####-####'"
                :error-messages="error.telefone1"
                v-model="paciente.telefone_1"
                :disabled="visualizar"
                :type="'text'"
                :rules="[
                  validationService.required('Campo Telefone é obrigatório.'),
                  validationService.isNotEmpty(),
                ]"
                placeholder="(99) 99999-9999"
                style="padding-top: 0 !important"
              >
              </v-text-field>
            </div>

            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="Telefone02">Telefone Fixo:</label>
              <v-text-field
                v-mask="'(##)####-####'"
                :error-messages="error.telefone2"
                v-model="paciente.telefone_2"
                :disabled="visualizar"
                :type="'text'"
                :placeholder="visualizar ? 'não informado' : '(99) 1234-1234'"
                style="padding-top: 0 !important"
              >
              </v-text-field>
            </div>

            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="Telefone03">Telefone Alternativo:</label>
              <v-text-field
                v-mask="'(##)#####-####'"
                :error-messages="error.telefone_2"
                v-model="telefone_3"
                :disabled="visualizar"
                :type="'text'"
                :placeholder="visualizar ? 'não informado' : '(99) 99999-9999'"
                style="padding-top: 0 !important"
              ></v-text-field>
            </div>

            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="CPF">CPF</label>
              <v-text-field
                v-mask="'###.###.###-##'"
                :error-messages="error.cpf"
                v-model="paciente.cpf"
                :disabled="visualizar"
                :type="'text'"
                :rules="[
                  validationService.required('Campo CPF é obrigatório.'),
                  validationService.isNotEmpty(),
                ]"
                placeholder="000.000.000-00"
                style="padding-top: 0 !important"
              >
              </v-text-field>
              <span class="text-muted">ex: "123.456.7890-12"</span>
            </div>
            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="RG">RG</label>
              <v-text-field
                :error-messages="error.rg"
                v-model="paciente.rg"
                :disabled="visualizar"
                :type="'text'"
                style="padding-top: 0 !important"
              >
              </v-text-field>
              <!-- <span class="text-muted">ex: "xx.xxx.xxx-x"</span> -->
            </div>
            <div class="col-lg-3 form-group clearfix mb-3">
              <label>Data de nascimento</label>
              <v-text-field
                :error-messages="error.data_nascimento"
                v-model="paciente.data_nascimento"
                :disabled="visualizar"
                clear-icon="clear"
                :rules="[
                  validationService.required(
                    'Campo Data de nascimento é obrigatório.'
                  ),
                  validationService.isNotEmpty(),
                ]"
                style="margin: 0 5px; padding-top: 5px !important"
                :type="'date'"
                :outlined="false"
              />
              <span class="text-muted">ex: "Dia/Mês/Ano"</span>
            </div>

            <div class="col-lg-3 form-group clearfix mb-3">
              <label class="">Gênero</label>
              <v-radio-group
                :error-messages="error.genero"
                v-model="paciente.genero"
                :rules="[
                  validationService.required('Campo Gênero é obrigatório.'),
                ]"
                :disabled="visualizar"
              >
                <v-radio label="Masculino" value="M" />
                <v-radio label="Feminino" value="F" />
                <v-radio label="Outros" value="O" />
              </v-radio-group>
            </div>

            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="CEP">CEP</label>
              <v-text-field
                v-mask="'#####-###'"
                :error-messages="error.cep"
                v-model="cep"
                :disabled="visualizar"
                @blur="searchCep"
                :type="'text'"
                :rules="[
                  validationService.required('Campo CEP é obrigatório.'),
                ]"
                placeholder="00000-000"
                style="padding-top: 0 !important"
              >
              </v-text-field>
              <span class="text-muted">ex: "xxxxx-xxx"</span>
            </div>

            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="Rua">Logradouro</label>
              <v-text-field
                :error-messages="error.logradouro"
                v-model="logradouro"
                :disabled="visualizar"
                :rules="[
                  validationService.required('Campo Logradouro é obrigatório.'),
                ]"
                :type="'text'"
                style="padding-top: 0 !important"
              >
              </v-text-field>
            </div>

            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="Bairro">Bairro</label>
              <v-text-field
                :error-messages="error.bairro"
                v-model="bairro"
                :disabled="visualizar"
                :rules="[
                  validationService.required('Campo Bairro é obrigatório.'),
                ]"
                :type="'text'"
                style="padding-top: 0 !important"
              >
              </v-text-field>
            </div>

            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="Numero">Número</label>
              <v-text-field
                :error-messages="error.numero"
                v-model="numero"
                :disabled="visualizar"
                :type="'text'"
                :rules="[
                  validationService.required('Campo Número é obrigatório.'),
                ]"
                style="padding-top: 0 !important"
              >
              </v-text-field>
            </div>

            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="Complemento">Complemento</label>
              <v-text-field
                :error-messages="error.complemento"
                v-model="complemento"
                :disabled="visualizar"
                :type="'text'"
                style="padding-top: 0 !important"
              >
              </v-text-field>
            </div>

            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="Referencia">Referência</label>
              <v-text-field
                :error-messages="error.referencias"
                v-model="referencias"
                :disabled="visualizar"
                :type="'text'"
                style="padding-top: 0 !important"
              >
              </v-text-field>
            </div>

            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="Cidade">Cidade</label>
              <v-text-field
                :error-messages="error.cidade"
                v-model="cidade"
                :disabled="visualizar"
                :rules="[
                  validationService.required('Campo Cidade é obrigatório.'),
                ]"
                :type="'text'"
                style="padding-top: 0 !important"
              ></v-text-field>
            </div>

            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="Estado">Estado</label>
              <v-text-field
                :error-messages="error.estado"
                v-model="estado"
                :disabled="visualizar"
                :rules="[
                  validationService.required('Campo Estado é obrigatório.'),
                ]"
                :type="'text'"
                style="padding-top: 0 !important"
              >
              </v-text-field>
            </div>

            <div class="col-lg-6 form-group clearfix mb-3" v-show="false">
              <label for="Convenio">Convênio</label>
              <v-text-field
                v-model="paciente.convenio"
                :disabled="visualizar"
                :type="'text'"
                style="padding-top: 0 !important"
              ></v-text-field>
            </div>

            <div
              class="col-12 form-group d-flex justify-space-between align-items-center"
            >
              <div class="col-lg-6 form-group clearfix p-0">
                <label for="nome">Observações</label>
                <v-textarea
                  class="p-0"
                  maxlength="200"
                  :disabled="visualizar"
                  v-model="observacao"
                  placeholder="Observações"
                  outlined
                  style="padding-top: 0 !important"
                ></v-textarea>
              </div>
              <button
                v-if="!visualizar"
                class="
                  btn btn-app-primary btn-flex btn-rounded
                  font-weight-bold
                  justify-content-end
                "
                @click.prevent="atualizarPaciente()"
              >
                <span v-if="!loading"> Salvar </span>
                <v-progress-circular
                  v-else
                  indeterminate
                  color="white"
                ></v-progress-circular>
              </button>
            </div>
          </v-form>
          <v-snackbar
            v-if="messageSnackbar"
            v-model="messageSnackbar"
            :color="typeMessageSnackbar"
            :timeout="-1"
          >
            {{ messageSnackbar }}
            <template v-slot:action="{ attrs }">
              <v-btn
                text
                :color="'white'"
                v-bind="attrs"
                @click="fecharSnackbar"
              >
                Ok
              </v-btn>
            </template>
          </v-snackbar>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import PacienteService from "@/services/paciente_service.js";
import ValidationService from "@/services/validation_service.js";
import CEPMixin from "@/mixins/cep_mixin.js";
import Mixin from "@/mixins/vuex_mixin.js";
import { mapActions } from "vuex";

export default {
  mixins: [CEPMixin, Mixin],
  props: {
    paciente: Object,
    visualizar: Boolean,
  },
  beforeMount() {
    this.validationService = ValidationService;
  },
  data() {
    return {
      addFoto: true,
      preview: "",
      foto: null,
      avatarPaciente: "",
      ValueEstadoCivil: "",
      itemsEstadoCivil: [
        "Solteiro",
        "Casado",
        "Separado",
        "Divorciado",
        "Viúvo",
        "Amasiado",
      ],
      messageSnackbar: "",
      typeMessageSnackbar: "",
      //Validação de Senha
      show1: false,
      show2: false,
      loading: false,
      password: "",
      // Formulário
      altura: "",
      peso: "",
      formCadastro: "",
      nome: "",
      email: "",
      telefone1: "",
      telefone2: "",
      telefone_3: "",
      cpf: "",
      rg: "",
      dataNascimento: "",
      genero: "",
      estadoCivil: "",
      observacoes: "",
      cep: "",
      logradouro: "",
      bairro: "",
      numero: "",
      complemento: "",
      referencias: "",
      cidade: "",
      estado: "",
      convenio: "",
      senha: "",
      error: {
        nome: "",
        email: "",
        telefone1: "",
        telefone2: "",
        cpf: "",
        rg: "",
        data_nascimento: "",
        genero: "",
        estadoCivil: "",
        observacoes: "",
        cep: "",
        logradouro: "",
        bairro: "",
        numero: "",
        complemento: "",
        referencias: "",
        cidade: "",
        estado: "",
        senha: "",
        statusResposta: null,
        validationService: {},
      },
      rules: {
        required: (value) => !!value || "Campo obrigatório.",
        min: (v) => v.length >= 8 || "Minimo de 8 caracteres",
        emailMatch: () => `O e-mail e a senha inseridos não correspondem`,
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Email inválido.";
        },
      },
      observacao: "",
    };
  },
  mounted() {
    console.log(this.paciente);
    this.formCadastro = document.forms[0];
    this.carregarDadosTutor();
    this.CEP_MIXIN_cep = this.paciente.cep;
  },
  computed: {
    imc() {
      try {
        const v =
          parseFloat(this.paciente.peso) /
          parseFloat(this.paciente.altura.replace(",", ".")) ** 2;
        if (isNaN(v)) return "";
        return v.toFixed(2);
      } catch (e) {
        return "";
      }
    },
    image_perfil() {
      var src = "";
      let tipo = this.paciente.tipo;
      if (!tipo) {
        return "";
      }
      tipo = this.paciente.tipo.toLowerCase();
      switch (tipo) {
        case "png":
          src = `data:image/png;base64,${this.paciente.foto}`;
          break;
        case "jpeg":
          src = `data:image/jpeg;base64,${this.paciente.foto}`;
          break;
        case "jpg":
          src = `data:image/jpeg;base64,${this.paciente.foto}`;
          break;
        default:
          src = "https://picsum.photos/id/103/200/300";
          break;
      }
      return src;
    },
  },
  // watch: {
  //   paciente() {
  //     this.CEP_MIXIN_logradouro = this.paciente.logradouro;
  //     this.CEP_MIXIN_bairro = this.paciente.bairro;
  //     this.CEP_MIXIN_cidade = this.paciente.cidade;
  //     this.CEP_MIXIN_estado = this.paciente.estado;
  //     this.CEP_MIXIN_cep = this.paciente.cep;
  //   },
  // },
  methods: {
    ...mapActions({
      $_ACTIONS_showSnackbarMessage: "showSnackbarMessage",
    }),
    searchCep() {
      this.$buscarCep(this, this.cep);
    },
    fecharSnackbar() {
      this.messageSnackbar = "";
      this.zerarErros();
    },
    zerarFormulario() {
      Array.from(this.formCadastro.elements).forEach((element) => {
        element.value = "";
      });
    },
    zerarErros() {
      Object.keys(this.error).forEach((error) => {
        this.error[error] = "";
      });
    },

    clean() {
      this.addFoto = true;
    },
    onChange() {
      const file = document.querySelector("input[type=file]").files[0];
      const reader = new FileReader();
      reader.onloadend = (e) => {
        this.preview = e.target.result;
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    },

    atualizarPaciente() {
      if (!this.$refs.form.validate()) {
        return;
      }
      const telefone_3 = this.telefone_3.replace(/[^0-9]/g, "");
      var formdata = new FormData();
      formdata.append("nome", this.paciente.nome);
      formdata.append("email", this.paciente.email);
      formdata.append("telefone_1", this.paciente.telefone_1);
      formdata.append("telefone_2", this.paciente.telefone_2);
      formdata.append("telefone_3", telefone_3);
      formdata.append("cpf", this.paciente.cpf);
      formdata.append("rg", this.paciente.rg);
      formdata.append("data_nascimento", this.paciente.data_nascimento);
      formdata.append("genero", this.paciente.genero);
      formdata.append("estado_civil", this.paciente.estadoCivil);
      formdata.append("profissao", this.paciente.profissao);
      formdata.append("cep", this.cep);
      formdata.append("peso", this.paciente.peso);
      formdata.append("altura", this.paciente.altura);
      formdata.append("logradouro", this.paciente.logradouro);
      formdata.append("bairro", this.bairro);
      formdata.append("cidade", this.cidade);
      formdata.append("estado", this.estado);
      formdata.append("numero", this.numero);
      formdata.append("complemento", this.complemento);
      formdata.append("referencias", this.referencias);
      formdata.append("observacao", this.observacao);
      formdata.append("convenio", this.paciente.convenio);
      formdata.append(
        "foto",
        document.querySelector("input[type=file]").files[0]
      );
      this.loading = true;
      const pacienteService = new PacienteService();
      const responseFunctions = {
        onSucess: (status) => (body) => {
          this.$_ACTIONS_showSnackbarMessage({
            message: body.message || this.$global.messages.internalServerError,
            color: status === 200 ? "sucess" : "error",
          });
        },
        onError: (error) => {
          this.messageSnackbar = error;
          this.typeMessageSnackbar = "error";
          console.log(error);
        },
        onEnd: () => {
          this.loading = false;
          // this.paciente = {};
          this.$emit("close");
        },
      };
      pacienteService.update(
        responseFunctions,
        formdata,
        this.paciente.id_paciente
      );
    },

    carregarDadosTutor() {
      this.telefone_3 = this.paciente.telefone_3 || "";
      this.cep = this.paciente.cep;
      this.logradouro = this.paciente.logradouro;
      this.bairro = this.paciente.bairro;
      this.numero = this.paciente.numero;
      this.complemento = this.paciente.complemento;
      this.referencias = this.paciente.referencias;
      this.cidade = this.paciente.cidade;
      this.estado = this.paciente.estado;
      this.observacao = this.paciente.observacao;
    },
  },
};
</script>

<style scoped>
.form-group label {
  text-align: left !important;
  display: block;
}

.form-group .label-genero {
  margin-right: 1rem;
}
</style>

<template>
  <v-card>
    <v-toolbar style="background-color: #3e682a; color: #fff">
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span class="headline">
          Consulta
          {{
            formatarData(
              horarioProp.data,
              horarioProp.hora_inicio,
              horarioProp.hora_final
            )
          }}</span
        >
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items> </v-toolbar-items>
    </v-toolbar>
    <div class="container">
      <div class="row">
        <div class="col-12 text-start padding-content">
          <h3>Tutor: {{ horarioProp.cliente_nome }}</h3>
          <h3>Pet: {{ horarioProp.pet_nome }}</h3>
          <h4>Procedimento: {{ horarioProp.procedimento_nome }}</h4>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-10">
          <v-textarea
            :disabled="perfilId != 56 && perfilId != 1 && perfilId != 2"
            label="Observação"
            v-model="observacao"
            placeholder=" "
            rows="3"
          ></v-textarea>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-7  form-group clearfix mb-0">
          <v-select
            :disabled="perfilId != 56 && perfilId != 1 && perfilId != 2"
            style="padding-top: 0 !important"
            :items="statusItems"
            :item-text="text"
            :item-value="value"
            v-model="status"
            label="Status"
          />
        </div>
        <div class="col-3  form-group clearfix mb-0">
          <v-btn
            class="mb-2"
            outlined
            color="#3e682a"
            :disabled="status == ''"
            @click="alterarStatus"
            v-if="perfilId == 56 || perfilId == 1 || perfilId == 2"
          >
            Alterar
          </v-btn>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import VuexSnackbarMixin from "@/mixins/vuex_snackbar_mixin.js";
import AgendaEspecialidadeService from "@/services/agendamento_especialidade_recepcao_service.js";
export default {
  mixins: [VuexSnackbarMixin],
  components: {},
  props: {
    horarioProp: Object,
  },
  data: () => ({
    status: "",
    observacao: "",
    statusItems: [
      {
        value: 1,
        text: "A Confirmar",
      },
      {
        value: 2,
        text: "Confirmado",
      },
      {
        value: 3,
        text: "Atendido",
      },
      {
        value: 4,
        text: "Cancelado",
      },
      {
        value: 5,
        text: "Remarcado",
      },
      {
        value: 6,
        text: "Não Compareceu",
      },
    ],
    services: {
      agendaEspecialidadeService: AgendaEspecialidadeService.build(),
    },
    perfilId: JSON.parse(sessionStorage.vuex).perfil.id,
  }),
  mounted() {},
  beforeMount() {
    this.status = parseInt(this.horarioProp.id_status);
    this.observacao = this.horarioProp.observacao;
  },
  computed: {},
  watch: {},
  methods: {
    formatarData(data, horaInicio, horaFim) {
      let arrData = data.split("-");
      return `${arrData[2]}/${arrData[1]}/${arrData[0]} ${horaInicio.substring(
        0,
        horaInicio.length - 3
      )} - ${horaFim.substring(0, horaFim.length - 3)}`;
    },
    getModel() {
      return {
        id_agenda_especialidade: parseInt(
          this.horarioProp.id_agenda_especialidade
        ),
        id_status: this.status,
        observacao: this.observacao,
      };
    },
    async alterarStatus() {
      console.log(this.getModel());
      await this.services.agendaEspecialidadeService
        .postAgendamentoRecepcaoUpdate(this.getModel())
        .then((response) => {
          if (response.status !== 200) {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Erro ao alterar consulta",
              color: "error",
            });
            return;
          }
          this.$_ACTIONS_showSnackbarMessage({
            message: "Consulta alterada com sucesso",
            color: "sucess",
          });
          this.$emit("close");
        });
    },
  },
};
</script>

<style scoped>
.headline {
  font-size: 1.1rem !important;
}
.padding-content {
  padding: 15px 30px;
}
</style>

<template>
  <div>
    <div class="p-lg-3">
      <div class="row" justify-content-between>
        <h3 class="mt-0 mb-2 font-weight-bold text-left">
          Pets atendidos por unidade
        </h3>
      </div>
    </div>
    <v-row align="center" class="my-3">
      <v-col class="d-flex col-3">
        <v-select
          outlined
          :items="unidades"
          label="Unidade"
          item-value="id"
          v-model="unidadeSelected"
        ></v-select>
      </v-col>
      <v-col class="d-flex col-3">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="datas"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              v-model="computedFormatDate"
              label="Data inicial - Data final"
              prepend-icon="mdi-calendar"
              clearable
              v-bind="attrs"
              v-on="on"
              @click:clear="datas = null"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="datas"
            no-title
            range
            scrollable
            color="green darken-4"
          >
            <v-spacer></v-spacer>
            <v-btn text @click="menu = false"> Cancelar </v-btn>
            <v-btn dark color="success" @click="$refs.menu.save(datas)">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col class="d-flex">
        <v-btn
          class="mb-7"
          style="width: 9rem; height: 3.2rem;"
          dark
          color="primary"
          @click="pdfRelatorio"
        >
          <span v-if="!loadingPdf">
            Exportar
          </span>
          <v-progress-circular
            v-else
            :width="3"
            :size="25"
            indeterminate
            color="white"
          ></v-progress-circular>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import UnidadeService from "../services/unidade_service";
import PetService from "../services/pets_service";

export default {
  mixins: [Mixin],
  data() {
    return {
      datas: null,
      menu: false,
      unidades: [],
      unidadeSelected: "",
      loadingPdf: false,
      services: {
        unidadeService: UnidadeService.build(),
        petService: PetService.build(),
        // procedimentoService: ProcedimentoService.build(),
        // formaPagamentoService: FormaPagamentoService.build(),
      },
      options: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 15,
        ordenacao: false,
        desc: false,
      },
    };
  },
  beforeMount() {
    this.setUnidades();
  },
  computed: {
    computedFormatDate() {
      const datasFormatadas =
        this.datas &&
        this.datas.map((item) =>
          item
            .split("-")
            .reverse()
            .join("/")
        );
      return datasFormatadas;
    },
  },
  methods: {
    setUnidades() {
      this.services.unidadeService
        .getUnidadePaginatedList({
          page: this.options.page,
          per_page: this.options.itemsPerPage,
          search: this.search,
          order: this.options.ordenacao,
          desc: this.options.desc,
        })
        .then((response) => {
          return response.json();
        })
        .then((response) => {
          this.unidades = response.data.unidades.map((unidade) => {
            return { text: unidade.nome_fantasia, id: unidade.unidade_id };
          });
        });
    },
    pdfRelatorio() {
      this.loadingPdf = true;
      const dataInicial = this.datas ? this.datas[0].split("-").join("") : "";
      const dataFinal = this.datas ? this.datas[1].split("-").join("") : "";
      const unidade = this.unidadeSelected ? this.unidadeSelected : "";

      if (!dataInicial || !dataFinal || unidade.length < 1) {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Preencha os campos corretamente",
          color: "error",
        });
        this.loadingPdf = false;
        return;
      }

      this.services.petService
        .pdfPetsAtendidosPorUnidadePdf(dataInicial, dataFinal, unidade)
        .then((resp) => {
          console.log(resp);
          if (resp.status) {
            console.log("chegoi aqui !!!!!");
            this.$_ACTIONS_showSnackbarMessage({
              message: "Falha ao carregar",
              color: "error",
            });
            return;
          }
          this.$_ACTIONS_showSnackbarMessage({
            message: "Dados encontrados com sucesso! Gerando PDF...",
            color: "sucess",
          });
          let link = document.createElement("a");
          // link.target = "_blank";
          link.download = `${dataInicial}-${dataFinal}.pdf`;
          let url = window.URL.createObjectURL(resp);
          link.href = url;
          link.click();
        })
        .catch(() => {
          this.$_ACTIONS_showSnackbarMessage({
            message: "Falha ao carregar",
            color: "error",
          });
        })
        .finally(() => {
          this.loadingPdf = false;
        });
    },
  },
};
</script>

<style></style>

import HTTPService from "@/services/http_service.js";
import { global } from "@/config/constants";

export default class ProcedimentoValorService extends HTTPService {
  resource = "procedimento-valor";

  static build() {
    return new ProcedimentoValorService();
  }

  getProcedimentoValorPaginatedList({
    page,
    per_page,
    order,
    desc,
    search,
    unidade_id,
    status
  }) {
    const filter = {
      page,
      per_page,
      order,
      desc,
      descricao: search,
    };

    if (unidade_id != 0) {
      console.log(unidade_id);
      console.log(status);
      filter.unidade_id = unidade_id;
    }
    if(status != 2){
      filter.status = status;
    }
    return this.get(this.resource, filter);
  }

  getProcedimentosByCadastroValor(convenioId, unidadeId) {
    return this.get(`procedimento-sem-valor/${unidadeId}/${convenioId}`);
    //   id_unidade: unidadeId,
    //   id_convenio: convenioId,
    // });
  }

  deletar(idProcedimentoValor, unidadeId) {
    return this.get(
      `procedimentovalor/deletar/${idProcedimentoValor}/${unidadeId}`
    );
  }

  async cadastroProcedimentoValor(procedimentoValor) {
    console.log(procedimentoValor);
    const headers = new Headers();
    const token = JSON.parse(sessionStorage.getItem("vuex")).token;
    headers.append("Authorization", "Bearer " + token);

    const requestOptions = {
      method: "POST",
      body: procedimentoValor,
      headers: headers,
    };
    return await fetch(
      `${global.api.baseURL}/${this.resource}`,
      requestOptions
    ).then(async (response) => {
      return response;
    });
  }

  async editar(procedimentoValorId, procedimentoValor) {
    const headers = new Headers();
    const token = JSON.parse(sessionStorage.getItem("vuex")).token;
    headers.append("Authorization", "Bearer " + token);

    const requestOptions = {
      method: "POST",
      body: procedimentoValor,
      headers: headers,
    };
    return await fetch(
      `${global.api.baseURL}/${this.resource}/${procedimentoValorId}`,
      requestOptions
    ).then(async (response) => {
      return response;
    });
  }

  async pdfProcedimentoValorRelatorio(unidade, search) {
    const filter = {
      unidade_id: unidade,
      descricao: search,
    };
    return await this.get(
        `relatorio/procedimento-valor-pdf`, filter
    ).then((resp) => {
      if (resp.status != 200) return resp;
      return resp.blob();
    });
  }

  async excelProcedimentoValorRelatorio(unidade, search) {
    const filter = {
      unidade_id: unidade,
      descricao: search,
    };
    return await this.get(
        `relatorio/procedimento-valor-excel`, filter
    ).then((resp) => {
      if (resp.status != 200) return resp;
      return resp.blob();
    });
  }
}
